
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/experiment/testimonials/control",
      function () {
        return require("private-next-pages/experiment/testimonials/control.page.jsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/experiment/testimonials/control"])
      });
    }
  