import React from 'react'
import axios from 'axios'
import Layout from 'src/components/layouts'
import TestimonialsPage from 'src/components/pages/BrandPages/Testimonials'
import logger from 'src/common/logger'

export async function getServerSideProps() {
  const { TRUSTPILOT_API_KEY, TRUSTPILOT_BUSINESS_ID } = process.env
  let reviewsRes = {}

  try {
    reviewsRes = await axios.get(
      `https://api.trustpilot.com/v1/business-units/${TRUSTPILOT_BUSINESS_ID}/reviews`,
      {
        params: {
          apikey: TRUSTPILOT_API_KEY,
          stars: '4,5',
          tagValue: 'Compliance-approved',
        },
      }
    )
  } catch (error) {
    logger.error('testimonials page: failed to get trustpilot data', { error })
  }

  return {
    props: {
      reviews: reviewsRes.data?.reviews || [],
    },
  }
}

const Testimonials = (pageProps) => {
  const { reviews } = pageProps

  return (
    <Layout
      isBrandPage={true}
      footerClassName="mb-5 lg:mb-0"
      dashboardClass="bg-blue-300 text-white"
    >
      <TestimonialsPage data={{ reviews }} />
    </Layout>
  )
}

export default Testimonials
